import React from "react";

import moment from "moment";
/** Utils */
import {
  AUTHENTICATOR_AUTHSTATE,
} from "./local-storage";


import { isBrowser } from "@utils/Helpers";
import { DEFAULT_USER_AUTH } from "./consts";


export const isLoggedIn = () =>{
  if(!isBrowser) return false;
  const status = localStorage.getItem(AUTHENTICATOR_AUTHSTATE);
  console.log('checking login status: ', status);
  return status === "signedIn"
}

// TODO implement this function in sign up
export const verifyEmail = async (email:string) =>{
  const url = `https://api.experianaperture.io/address/format/v1/${key}`;
  const headers = {
      'Auth-Token': addressToken
  };
  const result = await Axios.get(url, {headers: headers});
  if(result.status == 200){
      const data = result.data.result;
      return data.address;
  }
}

/** Return user auth object from local storage value */
// export const getStoredUserAuth = (): UserAuth => {
//   if (typeof window !== 'undefined'){
//     const auth = localStorage.getItem(USER_AUTH_KEY);
//     if (auth) {
//       return JSON.parse(auth);
//     }
//   }

//   return DEFAULT_USER_AUTH;
// };
